import React, { useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from '@hooks/useAuth.hook';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { OnPageLoaded } from '@link-loom/ui-sdk';
import { TextField, InputAdornment, IconButton } from '@mui/material';

import { SecurityService } from '@services';

const swal = withReactContent(Swal);

const statusCodeMessages = {
  461: 'The data provided does not match any registered application',
  462: 'Unauthorized user. After 3 failed attempts, your account will be locked for 24 hours.',
  463: 'The user is not registered in this application, needs to register',
  464: 'Unauthorized. After 3 failed attempts, your account will be locked for 24 hours.',
  401: 'Error authenticating',
};

function SetUI() {
  const element = document.querySelector('body');
  element.classList.add('authentication-bg');
}

async function signInWithPassword(payload, authProvider) {
  const entityService = new SecurityService();
  const entityResponse = await entityService.signInWithPassword(payload);

  if (!entityResponse || !entityResponse.result) {
    await swal.fire({
      title: 'Error al ingresar',
      text: statusCodeMessages[entityResponse.status] || 'Ocurrio un error',
      icon: 'error',
    });

    return;
  }

  authProvider.login(entityResponse.result);
}

function AuthSigninPassword() {
  const authProvider = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState('');

  useEffect(() => {
    const error = searchParams.get('error');

    const showError = (message) => {
      Swal.fire({
        title: 'Error al ingresar',
        text: message,
        icon: 'error',
      }).then(() => {
        searchParams.delete('error');
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      });
    };

    switch (error) {
      case 'insufficient_permissions':
        showError('No tienes suficientes permisos para ingresar.');
        break;
      case 'access_denied':
        showError('Tu cuenta no tiene acceso a esta aplicación.');
        break;
      default:
        break;
    }
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);

      if (!email) {
        await swal.fire({
          title: 'Error al ingrear',
          text: 'Por favor ingresa un email.',
          icon: 'error',
        });

        return;
      }

      if (!password) {
        await swal.fire({
          title: 'Error al ingrear',
          text: 'Por favor ingresa una contraseña.',
          icon: 'error',
        });

        return;
      }

      await signInWithPassword(
        {
          email,
          password,
        },
        authProvider,
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    SetUI();
  }, []);

  return (
    <>
      <div className="col-md-8 col-lg-6 col-xl-5">
        <div className="text-center">
          <a href="/">
            <img src="/assets/images/logo-dark-2.svg" alt="" height="75" className="mx-auto" />
          </a>
          <p className="text-muted mt-2 mb-3">Unlock your digital world</p>
        </div>
        <div className="card">
          <div className="card-body p-4">
            <div className="text-center mb-4">
              <h4 className="mt-0">Log in using email address</h4>
            </div>

            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              autoComplete="off"
            >
              <div className="mb-3">
                <TextField
                  className="w-100"
                  type="email"
                  id="email-input"
                  label="Email address"
                  value={email}
                  placeholder="Type your email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="etrune-email"
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="mb-3">
                <TextField
                  className="w-100"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPassword ? <i className="fe-eye-off" /> : <i className="fe-eye" />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <section className="d-flex justify-content-end mb-3">
                <section>
                  <Link to="recover-password" className="fs-5 ms-1 text-muted" replace>
                    <i className="fa fa-lock me-1 text-muted"></i>Forgot password?
                  </Link>
                </section>
              </section>

              <div className="mb-3 d-grid text-center">
                {isLoading ? (
                  <button className="btn btn-bordered-dark" disabled type="submit">
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-bordered-dark">
                    Log in
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <OnPageLoaded />
    </>
  );
}

export default AuthSigninPassword;
