import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InventoryItemService } from '@services';
import { Alert } from '@link-loom/ui-sdk';

const Loader = () => (
  <div className="d-flex justify-content-center">
    <div className="spinner-border m-2" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

function InventoryViewSettings() {
  const { id } = useParams();
  const [managementData, setManagementData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);
  const [formFields, setFormFields] = useState({
    id: '',
    organization_id: '',
    subscription_plan_id: '',
  });
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const entityService = new InventoryItemService();
      const entityResponse = await entityService.getByParameters({
        queryselector: 'organization-id',
        search: id,
      });

      if (
        !entityResponse ||
        !entityResponse.result.length ||
        !entityResponse.success
      ) {
        setIsAlertVisible(true);
      }

      if (entityResponse && !isAlertVisible) {
        setManagementData(entityResponse.result[0]);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [id]);

  useEffect(() => {
    if (managementData && Object.keys(managementData).length !== 0) {
      loadManagementData();
    }
  }, [managementData]);

  useEffect(() => {
    if (alertInfo.type === 'confirm' && isConfirm === true) {
      loadManagementData();
      setIsEditMode(false);
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const handleEditModeToggle = () => {
    if (!isEditMode) {
      setIsEditMode(true);
    } else {
      setAlertInfo({
        message:
          'Are you sure you want to disable edit mode? Any unsaved changes will be lost.',
        type: 'confirm',
      });
    }
  };

  const handleSaveChanges = async () => {
    try {
      const updatedData = {
        id: formFields.id,
        organization_id: id,
        subscription_plan_id: formFields.subscription_plan_id,
      };

      const entityService = new InventoryItemService();
      const entityResponse = await entityService.update(updatedData);
      if (!entityResponse || !entityResponse.result) {
        setAlertInfo({
          message: 'Something went wrong. Please try again.',
          type: 'error',
        });
        return null;
      }
      setIsEditMode(false);
      setManagementData({ ...formFields });
      setAlertInfo({
        message: 'Changes have been saved successfully!',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormFields({ ...formFields, [id]: value });
  };

  const loadManagementData = () => {
    setFormFields({
      id: managementData.id || '',
      organization_id: managementData.organization_id || '',
      subscription_plan_id: managementData.subscription_plan_id || '',
    });
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm':
        return {
          title: 'Confirm Action',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Accept',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  const fields = [
    { name: 'Inventory ID', id: 'organization_id', readOnly: true },
    { name: 'Subscription Plan ID', id: 'subscription_plan_id' },
  ];

  return (
    <div className="card-body">
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          {isAlertVisible ? (
            <div className="alert alert-danger bg-secondary text-white border-0 w-50 d-flex justify-content-center mx-auto">
              <span className="mx-auto">
                No data <strong>available</strong> at the moment!
              </span>
            </div>
          ) : (
            <section>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="header-title">
                  Manage the organization settings
                </h4>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="editModeSwitch"
                    checked={isEditMode}
                    onChange={handleEditModeToggle}
                  />
                  <label className="form-check-label" htmlFor="editModeSwitch">
                    Edit Mode
                  </label>
                </div>
              </div>

              <p className="sub-header">
                This form allows you to manage the settings of the organization.
                You can update the organization ID and subscription plan ID.
              </p>

              <form>
                <div className="row">
                  {fields.map((field, key) => (
                    <div
                      key={key}
                      className={field.className || 'col-md-6 mb-3'}
                    >
                      <label htmlFor={field.id} className="form-label">
                        {' '}
                        {field.name}{' '}
                      </label>
                      <input
                        type="text"
                        value={formFields[field.id] || ''}
                        id={field.id}
                        className="form-control"
                        readOnly={field.readOnly || !isEditMode}
                        onChange={handleChange}
                      />
                    </div>
                  ))}
                </div>
              </form>

              {isEditMode && (
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </section>
          )}
        </section>
      )}
    </div>
  );
}

export default InventoryViewSettings;
