import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OnPageLoaded } from '@link-loom/ui-sdk';
import { useAuth } from '@hooks/useAuth.hook';
import { useExternalScript } from '../../hooks/useExternalScript.hook';
import { UserService } from '../../services/index';

async function getEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse.result;
}

function DashboardAdmin() {
  const { setPageName } = useOutletContext();
  const { user } = useAuth();

  const knobScript = '/assets/libs/jquery-knob/jquery.knob.min.js';
  const dashboardScript = '/assets/js/pages/dashboard.admin.js';

  useExternalScript(knobScript);
  useExternalScript(dashboardScript);

  useEffect(() => {
    setPageName('Dashboard');
  }, []);

  return (
    <>
      <section className="container-fluid">
        {/* Insights */}
        <section className="row">
          <header className="col-12">
            <h4 className="header-title mb-2">Insights</h4>
          </header>

          <section className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mt-0 mb-4">Active users</h4>

                <div className="widget-chart-1">
                  <div className="widget-chart-box-1 float-start" dir="ltr">
                    <input
                      data-plugin="knob"
                      data-width="70"
                      data-height="70"
                      data-fgcolor="#f05050 "
                      data-bgcolor="#F9B9B9"
                      value={58}
                      readOnly={true}
                      data-skin="tron"
                      data-angleoffset="180"
                      data-readonly="true"
                      data-thickness=".15"
                    />
                  </div>

                  <div className="widget-detail-1 text-end">
                    <h2 className="fw-normal pt-2 mb-1"> 256 </h2>
                    <p className="text-muted mb-1">Total today</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <article className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mt-0 mb-3">New users</h4>

                <div className="widget-box-2">
                  <div className="widget-detail-2 text-end">
                    <span className="badge bg-success rounded-pill float-start mt-3">
                      32% <i className="mdi mdi-trending-up"></i>{' '}
                    </span>
                    <h2 className="fw-normal mb-1"> 8451 </h2>
                    <p className="text-muted mb-3">Total today</p>
                  </div>
                  <div className="progress progress-bar-alt-success progress-sm">
                    <div
                      className="progress-bar bg-success w-75"
                      role="progressbar"
                      aria-valuenow="77"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span className="visually-hidden">77% Complete</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mt-0 mb-4">Documents signed</h4>

                <div className="widget-chart-1">
                  <div className="widget-chart-box-1 float-start" dir="ltr">
                    <input
                      data-plugin="knob"
                      data-width="70"
                      data-height="70"
                      data-fgcolor="#ffbd4a"
                      data-bgcolor="#FFE6BA"
                      value={80}
                      readOnly={true}
                      data-skin="tron"
                      data-angleoffset="180"
                      data-readonly="true"
                      data-thickness=".15"
                    />
                  </div>
                  <div className="widget-detail-1 text-end">
                    <h2 className="fw-normal pt-2 mb-1"> 4569 </h2>
                    <p className="text-muted mb-1">Today</p>
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article className="col-xl-3 col-md-6">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mt-0 mb-3">Unsubscribed users</h4>

                <div className="widget-box-2">
                  <div className="widget-detail-2 text-end">
                    <span className="badge bg-pink rounded-pill float-start mt-3">
                      32% <i className="mdi mdi-trending-up"></i>{' '}
                    </span>
                    <h2 className="fw-normal mb-1"> 158 </h2>
                    <p className="text-muted mb-3">Total today</p>
                  </div>
                  <div className="progress progress-bar-alt-pink progress-sm">
                    <div
                      className="progress-bar bg-pink w-75"
                      role="progressbar"
                      aria-valuenow="77"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span className="visually-hidden">77% Complete</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>

        {/* Quick links */}
        <section className="row">
          <header className="col-12">
            <h4 className="header-title mb-2">Quick links</h4>
          </header>

          <article className="col-md-4 col-xl-2">
            <article className="card shadow">
              <section className="bg-light d-flex">
                <section className="bg-white d-flex dashboard-icon mx-auto rounded-3 dashboard-icon-shadow text-center my-3">
                  <i className="mdi mdi-diamond-stone font-35 my-auto mx-auto"></i>
                </section>
              </section>
              <section className="card-body text-center">
                <a href="/admin/item/management/daily#add-gemstone" className="btn btn-purple">
                  Add gem
                </a>
              </section>
            </article>
          </article>
        </section>

        {/* Management */}
        {user?.payload?.roles?.some((role) => role.slug === 'etruneid-admin') && (
          <section className="row">
            <header className="col-12">
              <h4 className="header-title mb-2">Management</h4>
            </header>

            <div className="col-md-4 col-xl-2">
              <article className="card shadow">
                <section className="bg-light d-flex">
                  <section className="bg-white d-flex dashboard-icon mx-auto rounded-3 dashboard-icon-shadow text-center my-3">
                    <i className="mdi mdi-warehouse font-35 my-auto mx-auto"></i>
                  </section>
                </section>
                <section className="card-body text-center">
                  <a href="/admin/inventory/management" className="btn btn-white">
                    Inventories
                  </a>
                </section>
              </article>
            </div>

            <div className="col-md-4 col-xl-2">
              <article className="card shadow">
                <section className="bg-light d-flex">
                  <section className="bg-white d-flex dashboard-icon mx-auto rounded-3 dashboard-icon-shadow text-center my-3">
                    <i className="mdi mdi-package-variant-closed font-35 my-auto mx-auto"></i>
                  </section>
                </section>
                <section className="card-body text-center">
                  <a href="/admin/item" className="btn btn-white">
                    Items
                  </a>
                </section>
              </article>
            </div>

            <div className="col-md-4 col-xl-2">
              <article className="card shadow">
                <section className="bg-light d-flex">
                  <section className="bg-white d-flex dashboard-icon mx-auto rounded-3 dashboard-icon-shadow text-center my-3">
                    <i className="mdi mdi-chart-line font-35 my-auto mx-auto"></i>
                  </section>
                </section>
                <section className="card-body text-center">
                  <a href="/admin/insight" className="btn btn-white">
                    Insights
                  </a>
                </section>
              </article>
            </div>

            <div className="col-md-4 col-xl-2">
              <article className="card shadow">
                <section className="bg-light d-flex">
                  <section className="bg-white d-flex dashboard-icon mx-auto rounded-3 dashboard-icon-shadow text-center my-3">
                    <i className="mdi mdi-cog font-35 my-auto mx-auto"></i>
                  </section>
                </section>
                <section className="card-body text-center">
                  <a href="/admin/user" className="btn btn-white">
                    Settings
                  </a>
                </section>
              </article>
            </div>
          </section>
        )}
      </section>
      <OnPageLoaded />
    </>
  );
}

export default DashboardAdmin;
