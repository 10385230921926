import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { InventoryManagementService } from '@services/index';
import { Alert } from '@link-loom/ui-sdk';

function InventoryCreate() {
  const { setPageName } = useOutletContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);
  const [formFields, setFormFields] = useState({
    display_name: '',
    slug: '',
    logo_url: '',
  });
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    setPageName('Organizations');
  }, []);

  useEffect(() => {
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const newData = {
        display_name: formFields.display_name,
        slug: formFields.slug,
        logo_url: formFields.logo_url,
      };

      const entityService = new InventoryManagementService();
      const entityResponse = await entityService.create(newData);
      if (!entityResponse || !entityResponse.success) {
        setAlertInfo({
          message:
            'An error occurred while creating the organization. Please try again.',
          type: 'error',
        });
        return null;
      }
      setFormFields({
        display_name: '',
        slug: '',
        logo_url: '',
      });
      setIsSubmitting(false);
      setAlertInfo({
        message: 'Organization created successfully!',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setAlertInfo({
        message:
          'An error occurred while creating the organization. Please try again.',
        type: 'error',
      });
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormFields({ ...formFields, [id]: value });
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  const fields = [
    { name: 'Name', id: 'display_name', required: true },
    { name: 'Slug', id: 'slug', required: true },
    { name: 'Logo URL', id: 'logo_url' },
  ];

  return (
    <div className="card shadow">
      {alertInfo.type && (
        <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />
      )}
      <section className="card-body">
        <header>
          <h4 className="header-title">Create New Organization</h4>
          <p className="sub-header">
            Fill out the form below to create a new organization. You can
            provide the name, slug, and logo URL for the new organization.
          </p>
        </header>

        <form onSubmit={handleSubmit}>
          <div className="row">
            {fields.map((field, key) => (
              <div key={key} className={field.className || 'col-md-6 mb-3'}>
                <label htmlFor={field.id} className="form-label">
                  {' '}
                  {field.name}{' '}
                </label>
                <input
                  type="text"
                  value={formFields[field.id] || ''}
                  id={field.id}
                  className="form-control"
                  onChange={handleChange}
                  required={field.required || false}
                />
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Creating...' : 'Create'}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
}

export default InventoryCreate;
