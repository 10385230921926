import BaseApi from '../base/api.service';

export default class AppManagementService extends BaseApi {
  constructor(args) {
    super(args);

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_SERVICE_VERIPASS_URL,
      get: '/app/management/',
      create: '/app/management/',
      update: '/app/management/',
      delete: '/app/management/',
    };
  }

  async getByParameters(data) {
    return super.getByParameters(data);
  }

  async update(data) {
    return super.update(data);
  }

  async create(data) {
    return super.create(data);
  }

  async delete(data) {
    return super.delete(data);
  }
}
