import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextField } from '@mui/material';
import { useAuth } from '@hooks/useAuth.hook';
import { InventoryItemService } from '@services';

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
  }
`;

const initialState = {
  name: '',
  primary_hex: '',
  primary_cmyk: '',
  secondary_hexadecimal: '',
  secondary_cmyk: '',
  is_multicolor: '',
};

function ItemColorUpdate({ entity = null, onUpdatedEntity = () => {}, isPopupContext = false }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(entity?.properties?.color ?? initialState);

  const handleDataChange = (fieldName, data) => {
    setEntityData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: data,
    }));
  };

  const getLogActions = () => {
    // Detect changes in color properties
    const originalEntity = entity?.properties?.color || {};
    const modifiedFields = Object.keys(entityData).reduce(
      (acc, key) => {
        if (entityData[key] !== originalEntity[key]) {
          acc.updated[key] = entityData[key];
          acc.original[key] = originalEntity[key];
        }
        return acc;
      },
      { original: {}, updated: {} },
    );

    return {
      details: 'Color updated',
      metadata: modifiedFields,
      user: { id: user.identity || '', metadata: user.payload }
    };
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      // Setup random ui settings
      const updatedEntity = {
        ...entity,
        properties: {
          ...entity.properties,
          color: entityData,
        },
      };

      if (updatedEntity._id) {
        delete updatedEntity._id;
      }

      updatedEntity.log = getLogActions();

      const entityResponse = await updateEntity(updatedEntity, InventoryItemService);

      if (!entityResponse || !entityResponse.success) {
        onUpdatedEntity('update', null);
        return null;
      }

      // Update parent entityDatas
      setIsLoading(false);
      onUpdatedEntity('update', entityResponse.result);
    } catch (error) {
      setIsLoading(false);
      onUpdatedEntity('update', null);
    }
  };

  useEffect(() => {
    if (entity) {
      setEntityData(entity?.properties?.color ?? initialState);
    }
  }, [entity]);

  return (
    <section>
      <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12 my-2' : ''}>
        <form onSubmit={handleSubmit}>
          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="name-input"
                label="Name"
                value={entityData.name}
                placeholder="Enter name"
                onChange={(event) => {
                  handleDataChange('name', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="primary-hex-input"
                label="Primary Hex"
                value={entityData.primary_hex}
                placeholder="#000000"
                onChange={(event) => {
                  handleDataChange('primary_hex', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="primary-cmyk-input"
                label="Primary CMYK"
                value={entityData.primary_cmyk}
                placeholder="0,0,0,0"
                onChange={(event) => {
                  handleDataChange('primary_cmyk', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="secondary-hexadecimal-input"
                label="Secondary Hexadecimal"
                value={entityData.secondary_hexadecimal}
                placeholder="#FFFFFF"
                onChange={(event) => {
                  handleDataChange('secondary_hexadecimal', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="secondary-cmyk-input"
                label="Secondary CMYK"
                value={entityData.secondary_cmyk}
                placeholder="0,0,0,0"
                onChange={(event) => {
                  handleDataChange('secondary_cmyk', event.target.value);
                }}
                autoComplete="off"
              />
            </section>

            <section className="mb-3 col-12">
              <TextField
                className="w-100"
                id="is-multicolor-input"
                label="Is Multicolor"
                value={entityData.is_multicolor}
                placeholder="true/false"
                onChange={(event) => {
                  handleDataChange('is_multicolor', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
              {isLoading ? (
                <button type="button" disabled className="btn btn-dark">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving changes
                </button>
              ) : (
                <button type="submit" className="btn btn-dark waves-effect waves-light">
                  Save changes
                </button>
              )}
            </section>
          </article>
        </form>
      </Container>
    </section>
  );
}

export default ItemColorUpdate;
