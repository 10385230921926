import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  InventoryViewManagement,
  InventoryViewInformation,
  InventoryViewSettings,
} from '@components/pages';

const dinamicComponent = {
  InventoryViewManagement,
  InventoryViewInformation,
  InventoryViewSettings,
};

function InventoryView() {
  const { setPageName } = useOutletContext();
  const [activeTab, setActiveTab] = useState('InventoryViewManagement');

  useEffect(() => {
    setPageName('Inventory');
  }, []);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const renderComponent = () => {
    const Component = dinamicComponent[activeTab];
    return Component ? <Component /> : null;
  };

  const tabs = [
    {
      name: 'Management',
      href: '#management',
      component: 'InventoryViewManagement',
      className: `nav-link ${
        activeTab === 'InventoryViewManagement' ? 'active' : ''
      }`,
    },
    {
      name: 'Information',
      href: '#information',
      component: 'InventoryViewInformation',
      className: `nav-link ${
        activeTab === 'InventoryViewInformation' ? 'active' : ''
      }`,
    },
    {
      name: 'Settings',
      href: '#settings',
      component: 'InventoryViewSettings',
      className: `nav-link ${
        activeTab === 'InventoryViewSettings' ? 'active' : ''
      }`,
    },
  ];

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="header-title mb-4">Organization Details </h4>
        <p className="text-muted font-14 mb-3">
          Welcome to the Organization View. This section allows you to explore
          and manage various aspects of your organization. Here, you can access
          important details, update information, configure settings, and
          collaborate with teams. Navigate through the tabs to access different
          functionalities and make informed decisions to drive your
          organization's success.
        </p>
        <ul className="nav nav-tabs nav-bordered">
          {tabs.map((tab, key) => (
            <li key={key} className="nav-item">
              <a
                href={tab.href}
                className={tab.className}
                onClick={() => handleTabChange(tab.component)}
              >
                {tab.name}
              </a>
            </li>
          ))}
        </ul>

        <div className="tab-content">{renderComponent()}</div>
      </div>
    </div>
  );
}

export default InventoryView;
