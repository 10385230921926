import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Tabs, Tab } from '@mui/material';
import { RetryMessage } from '@link-loom/ui-sdk';

import {
  ItemOverviewDetail,
  ItemColorDetail,
  ItemCutDetail,
  ItemLocationDetail,
  ItemMediaDetail,
  ItemNotesDetail,
  ItemPriceDetail,
  ItemScoreDetail,
  ItemSizeDetail,
  ItemTimelineDetail,
  ItemWeightDetail,
} from '@components/pages';
import { InventoryItemService } from '@services';

async function getEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.getByParameters(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }
  return entityResponse.result;
}

function ItemDetail() {
  const { id } = useParams();
  const [isItemNotFound, setIsItemNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [entity, setEntity] = useState({});
  const [statuses, setStatuses] = useState({});
  const [activeTabId, setActiveTabId] = useState('ItemOverviewDetail');

  const loadingState = { isLoading, setIsLoading };
  const entityState = { entity, setEntity };
  const dynamicComponentDefinitions = {
    ItemOverviewDetail,
    ItemColorDetail,
    ItemCutDetail,
    ItemLocationDetail,
    ItemMediaDetail,
    ItemNotesDetail,
    ItemPriceDetail,
    ItemScoreDetail,
    ItemSizeDetail,
    ItemTimelineDetail,
    ItemWeightDetail,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entity = await getEntity(
          {
            queryselector: 'id',
            search: id,
          },
          InventoryItemService,
        );
        const entityStatusesPromise = await getEntity(
          {
            queryselector: 'status',
            search: '',
          },
          InventoryItemService,
        );
        const [entityResponse, entityStatuses] = await Promise.all([entity, entityStatusesPromise]);

        if (!entityResponse || !entityResponse.items || !entityResponse.items.length) {
          setIsItemNotFound(true);
          setIsLoading(false);
          return;
        }

        const itemResponse = entityResponse?.items[0] ?? {};

        setEntity(itemResponse);
        setStatuses(entityStatuses);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  const handleTabChange = (_, newValue) => {
    setActiveTabId(newValue);
  };
  const dynamicComponent = () => {
    const Component = dynamicComponentDefinitions[activeTabId];
    const tabDefinition = tabs.find((tab) => tab.component === activeTabId);

    return Component ? (
      <Component
        relatedEntities={{ statuses }}
        loadingState={loadingState}
        entityState={entityState}
        entityRef={tabDefinition.id}
        entity={entity}
      />
    ) : null;
  };

  const tabs = [
    {
      id: 'item_management',
      name: 'General',
      component: 'ItemOverviewDetail',
      className: `nav-link ${activeTabId === 'ItemOverviewDetail' ? 'active' : ''}`,
    },
    {
      id: 'item_media',
      name: 'Media',
      component: 'ItemMediaDetail',
      className: `nav-link ${activeTabId === 'ItemMediaDetail' ? 'active' : ''}`,
    },
    {
      id: 'gem_cut',
      name: 'Cut',
      component: 'ItemCutDetail',
      className: `nav-link ${activeTabId === 'ItemCutDetail' ? 'active' : ''}`,
    },
    {
      id: 'gem_color',
      name: 'Color',
      component: 'ItemColorDetail',
      className: `nav-link ${activeTabId === 'ItemColorDetail' ? 'active' : ''}`,
    },
    {
      id: 'gem_weight',
      name: 'Weight',
      component: 'ItemWeightDetail',
      className: `nav-link ${activeTabId === 'ItemWeightDetail' ? 'active' : ''}`,
    },
    {
      id: 'gem_size',
      name: 'Size',
      component: 'ItemSizeDetail',
      className: `nav-link ${activeTabId === 'ItemSizeDetail' ? 'active' : ''}`,
    },
    {
      id: 'gem_score',
      name: 'Score',
      component: 'ItemScoreDetail',
      className: `nav-link ${activeTabId === 'ItemScoreDetail' ? 'active' : ''}`,
    },
    {
      id: 'gem_location',
      name: 'Location',
      component: 'ItemLocationDetail',
      className: `nav-link ${activeTabId === 'ItemLocationDetail' ? 'active' : ''}`,
    },
    {
      id: 'gem_price',
      name: 'Price',
      component: 'ItemPriceDetail',
      className: `nav-link ${activeTabId === 'ItemPriceDetail' ? 'active' : ''}`,
    },
    {
      id: 'gem_notes',
      name: 'Notes',
      component: 'ItemNotesDetail',
      className: `nav-link ${activeTabId === 'ItemNotesDetail' ? 'active' : ''}`,
    },
    {
      id: 'gem_historic',
      name: 'Timeline',
      component: 'ItemTimelineDetail',
      className: `nav-link ${activeTabId === 'ItemTimelineDetail' ? 'active' : ''}`,
    },
  ];

  return (
    <section className="row">
      <section className="col-xl">
        <section className="card">
          <section className="card-body">
            <header>
              <h4 className="header-title mb-2">Details</h4>
              <p className="text-muted font-14 mb-3">Here you can see and edit item information</p>
            </header>

            <aside>
              <Tabs
                value={activeTabId}
                onChange={handleTabChange}
                aria-label="Item Details Tabs"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabs.map((tab) => (
                  <Tab label={tab.name} value={tab.component} key={tab.name} />
                ))}
              </Tabs>
            </aside>

            <section className="tab-content pt-1 px-4">
              {isItemNotFound ? <RetryMessage /> : dynamicComponent()}
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}

export default ItemDetail;
