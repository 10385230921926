import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth.hook';

import {
  Alert,
  Placeholder,
  PopUp,
  useSnackbar,
  DataGrid,
  StatusChip,
  RetryMessage,
  SnapData,
} from '@link-loom/ui-sdk';
import { IconButton, Menu, MenuItem, ListItemText, Chip, Tooltip } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';

import { InventoryItemService } from '@services';

import { ItemQuickCreateGemstone, ItemQuickCreateMetal, ItemQuickDetail } from '@components/pages';

async function getEntity({ payload, service }) {
  try {
    const entityService = new service();

    const entityResponse = entityService.getByParameters(payload);

    return entityResponse;
  } catch (error) {
    return null;
  }
}

async function getComponentEntities() {
  try {
    const usersListPromiseResponse = getEntity({
      payload: {
        queryselector: 'date-rage',
        startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
        endDate: new Date(new Date().setHours(23, 59, 59, 999)).getTime(),
      },
      service: InventoryItemService,
    });

    const [usersListResponse] = await Promise.all([usersListPromiseResponse]);

    return { usersListResponse };
  } catch (error) {
    console.error(error);
  }
}

const PlaceholderComponent = () => {
  return (
    <Placeholder type="table" count={4}>
      <Placeholder type="title" count={1}>
        <Placeholder type="description" count={1} />
      </Placeholder>
    </Placeholder>
  );
};

const useHash = () => {
  const location = useLocation();
  return location.hash;
};

function ItemListDaily() {
  const { user } = useAuth();
  const { openSnackbar } = useSnackbar();
  const hash = useHash();
  const navigate = useNavigate();
  const [formattedEntities, setFormattedEntities] = useState([]);
  const [entities, setEntities] = useState([]);
  const [entitySelected, setEntitySelected] = useState(null);
  const [menuActionsAnchorElement, setMenuActionsAnchorElement] = useState(null);
  const [menuActionsSelected, setActionsSelected] = useState(null);
  const [isOpenItemQuickCreateGemstoneModal, setIsOpenItemQuickCreateGemstoneModal] =
    useState(false);
  const [isOpenItemQuickCreateMetalModal, setIsOpenItemQuickCreateMetalModal] = useState(false);
  const [isOpenItemQuickViewModal, setIsOpenItemQuickViewModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [alertConfigs, setAlertConfigs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isItemListEmpty, setIsItemListEmpty] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [isMeMode, setIsMeMode] = useState(true);
  const columns = [
    {
      field: 'report',
      headerName: 'Report',
      flex: 1,
      minWidth: 100,
      sortable: true,
      valueGetter: (params) => Number(params.row?.metadata?.report || 0),
      renderCell: (params) => {
        return (
          <section className="w-100">
            <section className="w-100">
              <span>{params.row?.metadata?.report}</span>
              <SnapData
                id="name"
                data={params?.row?.id}
                alignment="start"
                variant="small"
                className="text-muted"
              />
            </section>
          </section>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <section className="w-100">
            <span>{params.row?.name}</span>
          </section>
        );
      },
    },
    {
      field: 'cut_name',
      headerName: 'Cut',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <section className="w-100">
            <span>{params.row?.properties?.cut?.name}</span>
          </section>
        );
      },
    },
    {
      field: 'trade_measure',
      headerName: 'Measure',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row?.properties?.size?.trade_measure,
      renderCell: (params) => {
        return (
          <section className="w-100">
            <SnapData
              id="trade_measure"
              data={params.row?.properties?.size?.trade_measure}
              alignment="between"
              onEdit={() => {
                onEditItemProfile(params.row.id);
              }}
            />
          </section>
        );
      },
    },
    {
      field: 'trade_weight',
      headerName: 'Weight',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row?.properties?.weight?.trade_weight,
      renderCell: (params) => {
        return (
          <section className="w-100">
            <SnapData
              id="trade_weight"
              data={params.row?.properties?.weight?.trade_weight}
              alignment="right"
              onEdit={() => {
                onEditItemProfile(params.row.id);
              }}
            />
          </section>
        );
      },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <section className="w-100">
            <span>{params.row.quantity ? params.row.quantity : ''}</span>
          </section>
        );
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <section className="text-truncate">
            <Tooltip title={params.row.notes || ''} arrow>
              <span className="text-truncate">
                {params.row?.properties?.notes?.sort((a, b) => b.timestamp - a.timestamp)[0]
                  ?.note || ''}
              </span>
            </Tooltip>
          </section>
        );
      },
    },
    {
      field: 'assigned-to',
      headerName: 'Assigned To',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <section className="w-100">
            <span>{params.row.created?.user?.metadata?.profile?.display_name || ''}</span>
          </section>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'string',
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              disableRipple
              data-testid="user-action-menu-btn"
              aria-label="actions button"
              id={`list-item-menu-${params.row?.id}`}
              aria-haspopup="true"
              onClick={(event) =>
                actionsMenuOnClick(`list-item-menu-${params.row?.id}`, event.currentTarget)
              }
            >
              <i className="mdi mdi-dots-vertical fs-4"></i>
            </IconButton>
            <Menu
              elevation={1}
              id={'list-item-menu-' + params.row?.id}
              anchorEl={menuActionsAnchorElement}
              open={menuActionsSelected === `list-item-menu-${params.row?.id}`}
              onClose={closeMenuActions}
              MenuListProps={{
                'aria-labelledby': 'user-action-menu-btn',
              }}
            >
              <MenuItem
                onClick={(event) => {
                  closeMenuActions();
                  menuItemOnClick(event, 'quick-view', params);
                }}
                data-testid="user-quick-view-action-btn"
              >
                <i className="fe-search me-1"></i>
                <ListItemText>View</ListItemText>
              </MenuItem>

              <MenuItem
                component={Link}
                to={`/admin/item/management/${params.row.id}`}
                target="_blank"
                onClick={(event) => {
                  closeMenuActions();
                }}
                data-testid="user-edit-action-btn"
              >
                <i className="fe-edit-2 me-1"></i>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const onEditItemProfile = (id) => {
    navigate(`/admin/item/management/${id}`);
  };

  const menuItemOnClick = (event, action, params) => {
    event?.stopPropagation();

    itemOnAction(action, params?.row);
  };

  const filterEntities = () => {
    let formattedEntities = showDeleted
      ? entities
      : entities.filter((entity) => entity?.status?.name !== 'deleted');

    formattedEntities = isMeMode
      ? entities.filter((entity) => entity?.created?.user?.id === user.identity)
      : formattedEntities;

    setFormattedEntities(formattedEntities.map((entity) => entity));
  };

  const initializeComponent = async () => {
    const { usersListResponse } = await getComponentEntities();

    if (!usersListResponse?.success || !usersListResponse?.result?.items?.length) {
      setIsItemListEmpty(true);
    }

    if (usersListResponse && !isItemListEmpty) {
      const rawEntities = usersListResponse.result?.items || [];

      setEntities(rawEntities);
    }

    setIsLoading(false);
  };

  const itemOnCreated = (response) => {
    if (!response || !response.success) {
      setAlertConfigs({
        title: 'Error',
        description: 'Something was wrong while creating an item',
        typeIcon: 'error',
        confirmButtonText: 'Exit',
        cancelButtonText: 'Try again',
        cancelButtonClass: 'btn btn-white',
        showCancelButton: true,
        currentAction: 'created',
      });
      setShowAlert(true);
      return;
    }

    const entity = response.result ?? {};

    entityCreatedSuccessfully(entity);
  };

  const entityCreatedSuccessfully = (entity) => {
    const createdEntity = (prevState) => {
      const newEntities = [...prevState, entity];

      return newEntities;
    };

    setFormattedEntities(createdEntity);
    setEntities(createdEntity);

    setEntitySelected(entity);
    setIsItemListEmpty(false);

    if (showAlert) {
      return;
    }

    /* setAlertConfigs({
      title: 'Success',
      description: 'Item created successfully, you want to complete item?',
      typeIcon: 'success',
      confirmButtonText: 'Complete profile',
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn btn-white',
      showCancelButton: true,
      currentAction: 'created',
    }); */
    //setShowAlert(true);
    openSnackbar('Item created successfully', 'success');
  };

  const itemOnDeleted = (response) => {
    if (!response || !response.success) {
      setAlertConfigs({
        title: 'Error',
        description: 'Something was wrong while deleting user.',
        typeIcon: 'error',
        confirmButtonText: 'Exit',
        cancelButtonText: 'Try again',
        cancelButtonClass: 'btn btn-white',
        showCancelButton: true,
        currentAction: 'deleted',
      });
      setShowAlert(true);
      return;
    }

    const updatedEntity = response.result ?? {};
    const deleteEntity = (prevState) => {
      const newEntities = prevState.filter((_entity) => _entity.id !== updatedEntity.id);

      return newEntities;
    };
    const updateEntity = (prevState) => {
      return prevState.map((entity) => (entity.id === updatedEntity.id ? updatedEntity : entity));
    };

    if (showDeleted) {
      setFormattedEntities(updateEntity);
    } else {
      setFormattedEntities(deleteEntity);
    }

    openSnackbar('Item deleted successfully.', 'success');
  };

  const itemOnInactivated = (response) => {
    if (!response || !response.success) {
      setAlertConfigs({
        title: 'Error',
        description: 'Error while inactivating user.',
        typeIcon: 'error',
        confirmButtonText: 'Exit',
        cancelButtonText: 'Try again',
        cancelButtonClass: 'btn btn-white',
        showCancelButton: true,
        currentAction: 'deleted',
      });
      setShowAlert(true);
      return;
    }

    const updatedEntity = response.result ?? {};
    const updateEntity = (prevState) => {
      return prevState.map((entity) => (entity.id === updatedEntity.id ? updatedEntity : entity));
    };

    setFormattedEntities(updateEntity);

    openSnackbar('Item inactivated successfully.', 'success');
  };

  const onUpdatedEntity = (action, response) => {
    switch (action) {
      case 'create':
        itemOnCreated(response);
        break;
      case 'inactive':
        itemOnInactivated(response);
        break;
      case 'delete':
        itemOnDeleted(response);
        break;
    }
  };

  const closeMenuActions = () => {
    setActionsSelected(null);
    setMenuActionsAnchorElement(null);
  };

  const actionsMenuOnClick = (selector, anchorElement) => {
    setActionsSelected(selector || null);
    setMenuActionsAnchorElement(anchorElement || null);
  };

  const itemOnAction = (action, entity) => {
    setEntitySelected(entity);

    switch (action) {
      case 'quick-view':
        setIsOpenItemQuickViewModal(true);
        break;
      case 'create-gemstone':
        setIsOpenItemQuickCreateGemstoneModal(true);
        break;
      case 'create-metal':
        setIsOpenItemQuickCreateMetalModal(true);
        break;
      case 'show-deleted':
        setShowDeleted(!showDeleted);
      default:
        break;
    }
  };

  const alertOnConfirmed = (isConfirmed) => {
    setIsConfirmed(isConfirmed);

    if (!isConfirmed) {
      setShowAlert(false);
      return;
    }

    switch (alertConfigs.currentAction) {
      case 'created':
        navigate(`/admin/item/management/${entitySelected?.id}`);
        break;

      default:
        break;
    }

    setShowAlert(false);
  };

  const meModeOnClick = () => {
    setIsMeMode(!isMeMode);
  };

  useEffect(() => {
    if (isConfirmed === false) {
      setShowAlert(null);
      setIsConfirmed(null);
    }
  }, [isConfirmed, showAlert]);

  useEffect(() => {
    filterEntities();
  }, [entities, showDeleted, isMeMode]);

  useEffect(() => {
    if (!formattedEntities || !formattedEntities.length) {
      setIsItemListEmpty(true);
    } else {
      setIsItemListEmpty(false);
    }
  }, [formattedEntities]);

  useEffect(() => {
    if (hash === '#add-gemstone') {
      itemOnAction('create-gemstone', null);
    }
  }, [hash]);

  useEffect(() => {
    setIsLoading(true);
    initializeComponent();
  }, []);

  return (
    <section className="container-fluid">
      <div className="row">
        <div className="col-12">
          <article className="card shadow">
            {isLoading ? (
              PlaceholderComponent()
            ) : (
              <section className="card-body">
                <header className="d-flex flex-row justify-content-between">
                  <section>
                    <h4 className="mt-0 header-title">Management</h4>
                    <p className="text-muted font-14 mb-3">
                      Manage your inventory with ease. Here, you can view, add, and edit items.
                    </p>
                  </section>
                  <section className="align-items-sm-baseline d-flex dropdown">
                    <section>
                      {/* <button
                        className="btn btn-outline-primary mx-2"
                        onClick={(event) => {
                          event.preventDefault();
                          itemOnAction('create-metal', null);
                        }}
                      >
                        <i className="mdi mdi-plus me-1"></i> Add Metal
                      </button> */}
                      <button
                        className="btn btn-soft-success mx-2"
                        onClick={(event) => {
                          event.preventDefault();
                          itemOnAction('create-gemstone', null);
                        }}
                      >
                        <i className="mdi mdi-plus me-1"></i> Add gemstone
                      </button>
                    </section>

                    <section className="">
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        className="w-35px"
                        size="small"
                        onClick={(event) =>
                          actionsMenuOnClick('component-menu', event.currentTarget)
                        }
                      >
                        <i className="mdi mdi-dots-vertical fs-4"></i>
                      </IconButton>
                      <Menu
                        id={'component-menu'}
                        anchorEl={menuActionsAnchorElement}
                        open={menuActionsSelected === `component-menu`}
                        onClose={closeMenuActions}
                      >
                        {showDeleted ? (
                          <MenuItem
                            onClick={(event) => {
                              menuItemOnClick(event, 'show-deleted', {});
                              closeMenuActions();
                            }}
                          >
                            Hide deleted
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={(event) => {
                              menuItemOnClick(event, 'show-deleted', {});
                              closeMenuActions();
                            }}
                          >
                            Show deleted
                          </MenuItem>
                        )}
                      </Menu>
                    </section>
                  </section>
                </header>
                <section className="content">
                  <Chip
                    icon={<FaceIcon />}
                    label="Me mode"
                    variant={isMeMode ? 'filled' : 'outlined'}
                    color={isMeMode ? 'info' : 'default'}
                    sx={{ cursor: 'pointer' }}
                    className="mb-2"
                    onClick={meModeOnClick}
                  />
                  {isItemListEmpty ? (
                    <RetryMessage
                      message="Begin managing today's inventory by adding a new item."
                      buttonMessage="+ Add gemstone"
                      onRetryClick={() => {
                        itemOnAction('create-gemstone', null);
                      }}
                    />
                  ) : (
                    <DataGrid
                      columns={columns}
                      rows={formattedEntities}
                      localeText={undefined}
                      pageSizeOptions={[10, 20, 50, 100]}
                      disableRowSelectionOnClick={true}
                      initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                    />
                  )}
                </section>
              </section>
            )}
          </article>
        </div>
      </div>

      {showAlert === true && <Alert config={alertConfigs} setConfirm={alertOnConfirmed} />}
      <PopUp
        data-testid="user-create-modal"
        id="user-create-modal"
        isOpen={isOpenItemQuickCreateGemstoneModal}
        setIsOpen={setIsOpenItemQuickCreateGemstoneModal}
        className="col-lg-4 col-md-8 col-12"
      >
        <ItemQuickCreateGemstone
          onUpdatedEntity={onUpdatedEntity}
          entitySelected={entitySelected}
          setIsOpen={setIsOpenItemQuickCreateGemstoneModal}
          isPopupContext
        />
      </PopUp>
      <PopUp
        data-testid="user-create-metal-modal"
        id="user-create-metal-modal"
        isOpen={isOpenItemQuickCreateMetalModal}
        setIsOpen={setIsOpenItemQuickCreateMetalModal}
        className="col-lg-4 col-md-8 col-12"
      >
        <ItemQuickCreateMetal
          onUpdatedEntity={onUpdatedEntity}
          entitySelected={entitySelected}
          setIsOpen={setIsOpenItemQuickCreateMetalModal}
          isPopupContext
        />
      </PopUp>
      <PopUp
        data-testid="user-quick-view-modal"
        id="user-quick-view-modal"
        isOpen={isOpenItemQuickViewModal}
        setIsOpen={setIsOpenItemQuickViewModal}
        className="col-lg-4 col-md-8 col-12"
        styles={{
          closeButtonColor: 'text-light',
        }}
      >
        <ItemQuickDetail
          onUpdatedEntity={onUpdatedEntity}
          entity={entitySelected}
          setIsOpen={setIsOpenItemQuickViewModal}
          isPopupContext
        />
      </PopUp>
    </section>
  );
}

export default ItemListDaily;
