import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '@hooks/useAuth.hook';

import {
  TextField,
  FormHelperText,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useSnackbar } from '@link-loom/ui-sdk';
import { InventoryItemService } from '@services';

async function createEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.create(payload);

  if (!entityResponse || !entityResponse.result) {
    return null;
  }

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
    min-width: ${(props) => (props.$isPopup ? '95vw' : '100%')};
  }
`;

const metalNames = ['Gold', 'Silver', 'Platinum', 'Palladium', 'Rhodium', 'Titanium', 'Coper'];

const quantityUnit = ['Carat', 'Gram'];

const initialState = {
  type: { id: 2, name: 'metal', title: 'Metal' },
  report: '',
  name: '',
  cut_name: '',
  cut_shape: '',
  cut_style: '',
  weight_amount: '',
  weight_unit: 'Carat',
  size_height: '',
  size_width: '',
  size_depth: '',
  quantity: 1,
  notes: '',
};

function ItemQuickCreateMetal({ onUpdatedEntity, setIsOpen, isPopupContext }) {
  const { user } = useAuth();
  const { setPageName } = useOutletContext();
  const { openSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [itemData, setItemData] = useState(initialState);

  const resetForm = () => {
    setItemData(initialState);
  };

  useEffect(() => {
    setPageName('Item management');
  }, []);

  const handleDataChange = (fieldName, data) => {
    setItemData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: data,
    }));
  };

  const formatEntity = (item) => {
    return {
      name: item.name,
      type: item.type,
      quantity: item.quantity,
      notes: item.notes,
      metadata: {
        custom_id: item.custom_id,
        report: item.report,
      },
      properties: {
        cut: {
          name: item.cut_name,
          shape: item.cut_shape,
          style: item.cut_style,
        },
        size: {
          height: Number(item.size_height),
          width: Number(item.size_width),
          depth: Number(item.size_depth),
        },
        weight: {
          amount: Number(item.weight_amount),
          unit: item.weight_unit.toLocaleLowerCase(),
        },
      },
    };
  };

  const handleSubmit = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }

      if (!itemData?.name) {
        openSnackbar('Please provide a name', 'error');
        return;
      }

      setIsLoading(true);
      
      const item = formatEntity(itemData);
      item.created = { user: { id: user.identity || '', metadata: user.payload } };
      
      const response = await createEntity(item, InventoryItemService);

      // Update parent states
      setIsLoading(false);
      onUpdatedEntity('create', response);

      if (setIsOpen) {
        setIsOpen(false);
      }
    } catch (error) {
      setIsLoading(false);
      onUpdatedEntity('error', null);
      if (setIsOpen) {
        setIsOpen(false);
      }
    }
  };

  return (
    <section>
      <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12' : ''}>
        <div className="card mb-0">
          <div className="card-body py-4">
            <section className="row">
              <article className="col-12">
                <h4 className="header-title">Create {itemData?.type?.title ?? 'item'}</h4>
                <p className="sub-header">
                  To get started, fill out some basic information about the item you're adding to
                  the inventory.
                </p>
              </article>
            </section>

            <section>
              <form onSubmit={(event) => event.preventDefault()}>
                <article className="row">
                  <header className="my-1">
                    <h5>General information</h5>
                  </header>
                  <section className="mb-3 col-12 col-md-8">
                    <Autocomplete
                      disablePortal
                      id="name-autocomplete"
                      options={metalNames}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="w-100"
                          id="name-input"
                          label="Metal"
                          value={itemData.name}
                          placeholder=""
                          required
                          onChange={(event) => {
                            handleDataChange('name', event.target.value);
                          }}
                          autoComplete="off"
                        />
                      )}
                    />

                    <FormHelperText>Choose a name from list.</FormHelperText>
                  </section>
                  <section className="col-12 col-md-4">
                    <TextField
                      className="w-100"
                      type="number"
                      id="cut-name-input"
                      label="Report"
                      value={itemData.report}
                      placeholder="0"
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('report', event.target.value);
                      }}
                    />
                    <FormHelperText>Number from bag</FormHelperText>
                  </section>
                </article>
                <article className="row">
                  <section className="col-12 col-md-4">
                    <TextField
                      className="w-100"
                      type="number"
                      id="cut-name-input"
                      label="Quantity"
                      value={itemData.quantity}
                      placeholder="0"
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('quantity', event.target.value);
                      }}
                    />
                    <FormHelperText>How many gems in lot</FormHelperText>
                  </section>

                  <section className="col-12 col-md-4">
                    <TextField
                      className="w-100"
                      type="number"
                      id="weight-input"
                      label="Lot weight"
                      value={itemData.weight_carat}
                      placeholder="0"
                      helperText=""
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('weight_carat', event.target.value);
                      }}
                    />
                    <FormHelperText>Total weight of all gems</FormHelperText>
                  </section>

                  <section className="col-12 col-md-4">
                    <FormControl className="w-100" required>
                      <InputLabel id="weight-unit-label">Weight Unit</InputLabel>
                      <Select
                        labelId="weight-unit-label"
                        id="weight-unit-select"
                        value={itemData.weight_unit || ''}
                        onChange={(event) => {
                          handleDataChange('weight_unit', event.target.value);
                        }}
                        label="Weight Unit"
                      >
                        {quantityUnit.map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </section>
                </article>

                <article className="row">
                  <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
                    {isLoading ? (
                      <button type="button" disabled className="btn btn-primary">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="ms-2">Saving</span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success waves-effect waves-light"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    )}
                  </section>
                </article>
              </form>
            </section>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ItemQuickCreateMetal;
