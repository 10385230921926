import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OnPageLoaded } from '@link-loom/ui-sdk';

import { ItemDetail } from '@components/pages';

function ItemDetailPage() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Item management');
  }, []);

  return (
    <>
      <ItemDetail />
      <OnPageLoaded />
    </>
  );
}

export default ItemDetailPage;
