import BaseApi from '../base/api.service';

export default class SecurityService extends BaseApi {
  constructor(args) {
    super(args);

    this.api_key = process.env.REACT_APP_VERIPASS_API_KEY;
    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_SERVICE_VERIPASS_URL,
      signUpWithPassword: '/security/signup/standard',
      signInWithPassword: '/security/signin/standard',
      emailRecoverPassword: '/security/password/reset/standard',
      update: '/security/password/new/standard',
      logout: '/security/logout',
    };
  }

  async signUpWithPassword(data) {
    return super.post(data, {
      endpoint: this.serviceEndpoints.signUpWithPassword,
    });
  }

  async signInWithPassword(data) {
    return super.post(data, {
      endpoint: this.serviceEndpoints.signInWithPassword,
    });
  }

  async logout(data) {
    return super.post(data, { endpoint: this.serviceEndpoints.logout });
  }

  async emailRecoverPassword(data) {
    return super.post(data, {
      endpoint: this.serviceEndpoints.emailRecoverPassword,
    });
  }

  async newPassword(data) {
    return super.update(data);
  }
}
