import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextField } from '@mui/material';
import { useAuth } from '@hooks/useAuth.hook';
import { InventoryItemService } from '@services';

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
  }
`;

const initialState = {
  asset_current_price: '',
  asset_symbol: '',
  current_price: '',
  acquisition_cost: '',
};

function ItemPriceUpdate({ entity = null, onUpdatedEntity = () => {}, isPopupContext = false }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(entity?.properties?.price ?? initialState);

  const handleDataChange = (fieldName, data) => {
    setEntityData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: data,
    }));
  };

  const getLogActions = () => {
    // Detect changes in color properties
    const originalEntity = entity?.properties?.price || {};
    const modifiedFields = Object.keys(entityData).reduce(
      (acc, key) => {
        if (entityData[key] !== originalEntity[key]) {
          acc.updated[key] = entityData[key];
          acc.original[key] = originalEntity[key];
        }
        return acc;
      },
      { original: {}, updated: {} },
    );

    return {
      details: 'Price updated',
      metadata: modifiedFields,
      user: { id: user.identity || '', metadata: user.payload },
    };
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      // Setup random ui settings
      const updatedEntity = {
        ...entity,
        properties: {
          ...entity.properties,
          price: entityData,
        },
      };

      if (updatedEntity._id) {
        delete updatedEntity._id;
      }

      updatedEntity.log = getLogActions();

      const entityResponse = await updateEntity(updatedEntity, InventoryItemService);

      if (!entityResponse || !entityResponse.success) {
        onUpdatedEntity('update', null);
        return null;
      }

      // Update parent entityDatas
      setIsLoading(false);
      onUpdatedEntity('update', entityResponse.result);
    } catch (error) {
      setIsLoading(false);
      onUpdatedEntity('update', null);
    }
  };

  useEffect(() => {
    if (entity) {
      setEntityData(entity?.properties?.price ?? initialState);
    }
  }, [entity]);

  return (
    <section>
      <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12 my-2' : ''}>
        <form onSubmit={handleSubmit}>
          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="asset-current-price-input"
                label="Asset Current Price"
                value={entityData.asset_current_price}
                placeholder="Enter asset current price"
                onChange={(event) => {
                  handleDataChange('asset_current_price', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="asset-symbol-input"
                label="Asset Symbol"
                value={entityData.asset_symbol}
                placeholder="Enter asset symbol"
                onChange={(event) => {
                  handleDataChange('asset_symbol', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="current-price-input"
                label="Current Price"
                value={entityData.current_price}
                placeholder="Enter current price"
                onChange={(event) => {
                  handleDataChange('current_price', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="acquisition-cost-input"
                label="Acquisition Cost"
                value={entityData.acquisition_cost}
                placeholder="Enter acquisition cost"
                onChange={(event) => {
                  handleDataChange('acquisition_cost', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
              {isLoading ? (
                <button type="button" disabled className="btn btn-dark">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving changes
                </button>
              ) : (
                <button type="submit" className="btn btn-dark waves-effect waves-light">
                  Save changes
                </button>
              )}
            </section>
          </article>
        </form>
      </Container>
    </section>
  );
}

export default ItemPriceUpdate;
