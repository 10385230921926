import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import {
  AccountGettingStarted,
  AccountOverview,
  AccountProfile,
  AuthRecoverPassword,
  AuthNewPassword,
  AuthSigninPassword,
  AuthLogin,
  AuthLogout,
  AuthBridge,
  AuthLockscreen,
  DashboardAdmin,
  InsightHome,
  InsightFinance,
  InsightClients,
  InsightQuality,
  InsightService,
  InsightCondensed,
  MaintenanceGeneral,
  ItemDetailPage,
  ItemManagementPage,
  ItemManagementDailyPage,
  ItemQuickCreatePage,
  InventoryManagement,
  InventoryView,
  InventoryCreate,
} from './pages/index';

import { LayoutAdmin, LayoutAuth, LayoutMaintenance } from './layouts/index';
import { AuthProvider } from './hooks/useAuth.hook';

import { Snackbar } from '@link-loom/ui-sdk';

function App() {
  return (
    <BrowserRouter>
      <Snackbar>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<LayoutAuth />}>
              <Route index element={<AuthBridge />} />
            </Route>
            <Route path="/auth" element={<LayoutAuth />}>
              <Route index element={<MaintenanceGeneral />} />
              <Route path="login" element={<AuthSigninPassword />} />
              <Route path="logout" element={<AuthLogout />} />
              <Route path="lock-screen" element={<AuthLockscreen />} />
              <Route path="recover-password" element={<AuthRecoverPassword />} />
              <Route path="new-password" element={<AuthNewPassword />} />
            </Route>
            <Route path="/admin" element={<LayoutAdmin />}>
              <Route index element={<DashboardAdmin />} />
              <Route path="account">
                <Route index path="overview" element={<AccountOverview />} />
                <Route element={<AccountGettingStarted />} />
                <Route path="profile" element={<AccountProfile />} />
              </Route>
              <Route path="insight">
                <Route path="home" element={<InsightHome />} />
                <Route index element={<InsightCondensed />} />
                <Route path="clients" element={<InsightClients />} />
                <Route path="finance" element={<InsightFinance />} />
                <Route path="quality" element={<InsightQuality />} />
                <Route path="service" element={<InsightService />} />
              </Route>
              <Route path="inventory">
                <Route path="management" element={<InventoryManagement />} />
                <Route path="management/:id" element={<InventoryView />} />
                <Route path="create" element={<InventoryCreate />} />
              </Route>
              <Route path="item">
                <Route index element={<ItemManagementPage />} />
                <Route path="management" element={<ItemManagementPage />} />
                <Route path="management/daily" element={<ItemManagementDailyPage />} />
                <Route path="management/:id" element={<ItemDetailPage />} />
                <Route path="create" element={<ItemQuickCreatePage />} />
              </Route>
              <Route path="*" element={<MaintenanceGeneral />} />
            </Route>

            <Route path="*" element={<LayoutMaintenance />}>
              <Route path="*" element={<MaintenanceGeneral />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Snackbar>
    </BrowserRouter>
  );
}

export default App;
