import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth.hook';
import { Spinner, SnapData } from '@link-loom/ui-sdk';
import { Card, Typography, Avatar } from '@mui/material/';

const ItemNotesPreview = ({ entity, setIsOpen, isPopupContext }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const confirmOnClick = async () => {
    setIsOpen(false);
    navigate(`/admin/users/management/${entity?.id}`);
  };

  const closeOnClick = async () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsLoading(false);
    console.log(entity);

    setRows(entity.properties.notes);
  }, [entity]);

  useEffect(() => {
    console.log('rows', rows);
  }, [rows]);

  useEffect(() => {
    console.log('ItemNotesPreview component mounted');

    return () => {
      console.log('ItemNotesPreview component unmounted');
    };
  }, []);

  return (
    <section className="card-body p-0">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {rows.map((note, index) => (
            <div
              key={index}
              className={`d-flex ${
                note?.user?.id === user.identity ? 'justify-content-end' : 'justify-content-start'
              } mb-3`}
            >
              {note?.user?.id !== user.identity && (
                <Avatar className="me-2">
                  {note?.user?.metadata?.profile?.profile_ui_settings?.profile_picture_url ||
                    '/assets/images/characters/character-unknown.svg'}
                </Avatar>
              )}

              <Card className="p-3 w-75">
                <Typography variant="body2" color="textSecondary">
                  {new Date(+note.timestamp).toLocaleString()}
                </Typography>
                <Typography variant="body1">{note.note}</Typography>
                <Typography variant="caption" color="textSecondary">
                  - {note.user?.metadata?.profile?.display_name || ''}
                </Typography>
              </Card>

              {note?.user?.id === user.identity && (
                <Avatar
                  className="ms-2"
                  src={
                    note?.user?.metadata?.profile?.profile_ui_settings?.profile_picture_url ||
                    '/assets/images/characters/character-unknown.svg'
                  }
                ></Avatar>
              )}
            </div>
          ))}
        </>
      )}
      {isPopupContext && (
        <section className="d-flex justify-content-center mt-4 pb-3">
          <button
            title="Submit"
            type="submit"
            className="btn btn btn-white btn-action mx-2"
            onClick={closeOnClick}
          >
            <i className="bi bi-check"></i> Close
          </button>
          <button
            title="Edit user"
            type="submit"
            className="btn btn-soft-success btn-action mx-2"
            onClick={confirmOnClick}
          >
            <i className="bi bi-check"></i> Edit user
          </button>
        </section>
      )}
    </section>
  );
};

export default ItemNotesPreview;
