import React from 'react';

function FooterAdmin() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            {new Date().getFullYear()} &copy; All rights reserved to{' '}
            <a href="https://etrune.com" target="_blank">Etrune LLC</a>
          </div>
          <div className="col-md-6">
            <div className="text-md-end footer-links d-none d-sm-block">
              <a href="/tutorials">Tutorials</a>
              <a href="/help">Help</a>
              <a href="/contact">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterAdmin;
