import React, { useState, useEffect } from 'react';

import { Spinner, useSnackbar } from '@link-loom/ui-sdk';

import { InventoryItemService } from '@services';

async function updateEntity(payload, service) {
  try {
    const entityService = new service();
    const entityResponse = await entityService.update(payload);

    return entityResponse;
  } catch (error) {
    return null;
  }
}

async function getEntityList({ payload, service }) {
  try {
    const entityService = new service();

    const entityResponse = entityService.getByParameters(payload);

    return entityResponse;
  } catch (error) {
    return null;
  }
}

async function getComponentEntities() {
  try {
    const userStatusesListPromiseResponse = getEntityList({
      payload: {
        queryselector: 'statuses',
        search: '',
      },
      service: InventoryItemService,
    });

    const [userStatusesListResponse] = await Promise.all([userStatusesListPromiseResponse]);

    return { userStatusesListResponse };
  } catch (error) {
    console.error(error);
  }
}

const ItemDelete = ({ entitySelected, onUpdatedEntity, setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { openSnackbar } = useSnackbar();

  const [statuses, setStatuses] = useState({});
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const initializeComponent = async () => {
    const { userStatusesListResponse } = await getComponentEntities();

    if (
      !userStatusesListResponse ||
      !userStatusesListResponse.success ||
      !userStatusesListResponse.result
    ) {
      setIsErrorVisible(true);
    }

    if (userStatusesListResponse && !isErrorVisible) {
      setStatuses(userStatusesListResponse.result || {});
    }

    setIsLoading(false);
  };

  const handleEntityStatusUpdate = async (entity, status) => {
    try {
      const response = await updateEntity({ ...entity, status }, InventoryItemService);

      if (!response || !response.success) {
        openSnackbar('Something was wrong, please try again.', 'error');
        return null;
      }

      openSnackbar('Item deleted successfully.', true);

      onUpdatedEntity('delete', response);

      setIsOpen(false);
    } catch (error) {
      openSnackbar('There was an unexpected error!', false);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmOnClick = async () => {
    setIsLoading(true);

    handleEntityStatusUpdate(entitySelected, statuses.deleted);
  };

  const returnOnClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    initializeComponent();
  }, []);

  return (
    <div className="card-body">
      {isLoading ? (
        <Spinner />
      ) : isErrorVisible ? (
        <div className="alert alert-danger  bg-secondary text-white border-0 d-flex justify-content-center mx-auto">
          <span className="mx-auto">
            No user status <strong> available at the moment</strong>, try in a while
          </span>
        </div>
      ) : (
        <div className="container pt-2 text-center mb-0">
          <h2 className="card-title mb-0">Delete Item</h2>
          <p className="fw-bold text-danger mb-2">Are you sure you want to delete this user?</p>
          <p className="mb-3 text-muted">{entitySelected?.display_name}</p>

          <button
            title="Cancel"
            type="button"
            className="btn btn-light btn-action mx-2"
            onClick={returnOnClick}
          >
            <i className="bi bi-x"></i> Cancel
          </button>
          <button
            title="Submit"
            type="submit"
            className="btn btn-danger btn-action mx-2"
            onClick={confirmOnClick}
          >
            <i className="bi bi-check"></i> Yes, delete
          </button>
        </div>
      )}
    </div>
  );
};

export default ItemDelete;
