import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SecurityService, AppManagementService } from '@services';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const swal = withReactContent(Swal);

function SetUI() {
  const element = document.querySelector('body');
  element.classList.add('authentication-bg');
}

async function newPassword(payload) {
  const entityService = new SecurityService();
  const entityResponse = await entityService.newPassword(payload);

  if (!entityResponse || !entityResponse.result) {
    await swal.fire({
      title: 'Error',
      text: entityResponse.message || 'An error ocurred',
      icon: 'error',
    });

    return;
  }

  return entityResponse;
}

function AuthNewPassword() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [appEntity, setAppEntity] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const appIdFromParams = searchParams.get('appId');

    if (appIdFromParams) {
      const getAppById = async () => {
        try {
          const entityService = new AppManagementService();
          const entityResponse = await entityService.getByParameters({
            queryselector: 'id',
            search: appIdFromParams,
          });

          if (!entityResponse || !entityResponse?.result) {
            await swal.fire({
              title: 'Error',
              text: entityResponse.message || 'An error ocurred',
              icon: 'error',
            });

            return;
          }

          setAppEntity(entityResponse?.result?.[0]);
        } catch (error) {
          console.error(error);
        }
      };

      getAppById();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let queries = {};

      if (!password || !password.length < 0) {
        await swal.fire({
          title: 'Error',
          text: 'You must enter your password, please.',
          icon: 'error',
        });

        return;
      }

      for (let pair of searchParams.entries()) {
        const [key, value] = pair;
        queries[key] = value;
      }

      await newPassword({
        ...queries,
        password,
      });

      swal.fire('Congratulations!', "You've changed your password", 'success');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    SetUI();
  }, []);

  return (
    <>
      <div className="col-md-8 col-lg-6 col-xl-4">
        <div className="text-center">
          <a href="/">
            <img
              src="/assets/images/logo-dark-2.svg"
              alt="Etrune ID logo"
              height="22"
              className="mx-auto"
            />
          </a>
          <p className="text-muted mt-2 mb-4">
            Channelize Simplicity: Unifying Data, Powering Possibilities!
          </p>
        </div>
        <div className="card">
          <div class="card-body p-4">
            <div class="text-center mb-4">
              <h4 class="text-uppercase mt-0 mb-3">New password</h4>
              <p class="text-muted mb-0">
                Enter your new password, remember not to forget it!
              </p>
            </div>

            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              autoComplete="off"
            >
              <div class="mb-3">
                <label htmlFor="emailaddress" class="form-label">
                  Password
                </label>
                <input
                  class="form-control"
                  type="password"
                  placeholder="Enter a password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div class="mb-3 text-center d-grid">
                {isLoading ? (
                  <button className="btn btn-primary" disabled type="submit">
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loadin...
                  </button>
                ) : (
                  <button class="btn btn-primary" type="submit">
                    Change
                  </button>
                )}
              </div>
            </form>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <p class="text-muted">
                Did you remember your password?
                <a
                  href={
                    appEntity?.app_frontend_url
                      ? appEntity?.app_frontend_url + '/auth/login'
                      : '/auth/login'
                  }
                >
                  <b className="ms-1">Log in</b>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthNewPassword;
