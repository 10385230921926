import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextField } from '@mui/material';
import { useAuth } from '@hooks/useAuth.hook';
import { InventoryItemService } from '@services';

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
  }
`;

const initialState = {
  note: '',
  timestamp: '',
  user: {},
};

function ItemNotesUpdate({ entity = null, onUpdatedEntity = () => {}, isPopupContext = false }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(initialState);

  const handleDataChange = (fieldName, data) => {
    setEntityData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: data,
    }));
  };

  const getLogActions = (note) => {
    const modifiedFields = { original: { note: '' }, updated: { note: note.note } };

    return {
      details: 'Added note',
      metadata: modifiedFields,
      user: { id: user.identity || '', metadata: user.payload },
    };
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      // Setup random ui settings
      const newNote = {
        note: entityData.note,
        timestamp: new Date().getTime() + '',
        user: { id: user.identity || '', metadata: user.payload },
      };
      const updatedEntity = {
        ...entity,
        properties: {
          ...entity.properties,
          notes: [...(entity.properties.notes || []), newNote],
        },
      };

      if (updatedEntity._id) {
        delete updatedEntity._id;
      }

      updatedEntity.log = getLogActions(newNote);

      const entityResponse = await updateEntity(updatedEntity, InventoryItemService);

      if (!entityResponse || !entityResponse.success) {
        onUpdatedEntity('update', null);
        return null;
      }

      // Update parent states
      setIsLoading(false);
      onUpdatedEntity('update', entityResponse.result);
    } catch (error) {
      setIsLoading(false);
      onUpdatedEntity('update', null);
    }
  };

  useEffect(() => {
    if (entity) {
      setEntityData(initialState);
    }
  }, [entity]);

  return (
    <section>
      <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12 my-2' : ''}>
        <form onSubmit={handleSubmit}>
          <article className="row">
            <section className="mb-3 col-12">
              <TextField
                className="w-100"
                id="note-input"
                label="Note"
                value={entityData.note}
                placeholder="Enter note"
                onChange={(event) => {
                  handleDataChange('note', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
              {isLoading ? (
                <button type="button" disabled className="btn btn-dark">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving changes
                </button>
              ) : (
                <button type="submit" className="btn btn-dark waves-effect waves-light">
                  Save changes
                </button>
              )}
            </section>
          </article>
        </form>
      </Container>
    </section>
  );
}

export default ItemNotesUpdate;
