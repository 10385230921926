import React, { useEffect } from 'react';
import { useAuth } from '@hooks/useAuth.hook';

function SetUI() {
  useEffect(() => {
    const element = document.querySelector('body');
    element.classList.add('authentication-bg');
  }, []);
}

function AuthLogout() {
  const authProvider = useAuth();
  useEffect(() => {
    authProvider.logout();
  }, []);

  SetUI();

  return (
    <div className="col-md-8 col-lg-6 col-xl-4">
      <div className="card bg-pattern">
        <div className="card-body p-4">
          <div className="text-center w-75 m-auto">
            <div className="auth-logo">
              <a href="/" className="logo logo-dark text-center">
                <span className="logo-lg">
                  <img src="/assets/images/logo-dark-2.svg" alt="" />
                </span>
              </a>

              <a href="/" className="logo logo-light text-center">
                <span className="logo-lg">
                  <img src="/assets/images/logo-light.svg" alt="" />
                </span>
              </a>
            </div>
          </div>

          <div className="text-center">
            <div className="mt-4">
              <div className="logout-checkmark">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                >
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#4bd396"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#4bd396"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
              </div>
            </div>

            <h3>Hasta luego!</h3>

            <p className="text-muted"> Has cerrado sesión exitosamente. </p>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 text-center">
          <p className="text-muted">
            Volver a{' '}
            <a href="/auth/login" className="text-dark ms-1">
              <b>Ingresar</b>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AuthLogout;
