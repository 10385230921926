import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, SnapData } from '@link-loom/ui-sdk';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { Typography } from '@mui/material';

import { Done, SwapHoriz } from '@mui/icons-material';

const ItemTimelinePreview = ({ entity, setIsOpen, isPopupContext }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const renderMetadataChanges = (metadata) => {
    if (!metadata || !metadata.original || !metadata.updated) {
      return (
        <Typography variant="body2" component="div">
          No metadata available
        </Typography>
      );
    }

    return Object.keys(metadata.original).map((key) => (
      <Typography key={key} variant="body2" component="div">
        <strong>{key}</strong>: "{metadata.original[key] || ''}" → "{metadata.updated[key] || ''}"
      </Typography>
    ));
  };

  const renderUserInfo = (user) => {
    return (
      <>
        <Typography variant="body2" component="div">
          {user?.metadata?.profile?.display_name || 'No name'} (ID: {user?.id})
        </Typography>
        <Typography variant="body2" component="div">
          {user?.metadata?.profile?.primary_email_address}
        </Typography>
        <Typography variant="body2" component="div">
          {typeof user?.metadata?.profile?.primary_phone_number === 'object'
            ? `+${user?.metadata?.profile?.primary_phone_number?.country?.dial_code} ${user?.metadata?.profile?.primary_phone_number?.phone_number}`
            : user?.metadata?.profile?.primary_phone_number || 'No phone number'}
        </Typography>
      </>
    );
  };

  useEffect(() => {
    setIsLoading(false);
    console.log('entity', entity);

    setRows(entity?.history);
  }, [entity]);

  useEffect(() => {
    console.log('rows', rows);
  }, [rows]);

  useEffect(() => {
    console.log('ItemTimelinePreview component mounted');

    return () => {
      console.log('ItemTimelinePreview component unmounted');
    };
  }, []);

  return (
    <section className="card-body p-0">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Timeline position="left">
            {rows.map((row) => (
              <TimelineItem key={row.timestamp}>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2">
                  {new Date(+row?.timestamp).toLocaleString()}

                  {renderUserInfo(row?.user)}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color={row?.action === 'created' ? 'success' : 'primary'}>
                    {row?.action === 'created' ? <Done /> : <SwapHoriz />}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <Typography variant="h6" component="span" className="text-capitalize text-black">
                    {row?.action?.charAt(0).toUpperCase() + row?.action.slice(1)}
                  </Typography>
                  <Typography variant="body2">{row?.action === 'created' ? 'Item created' : row?.details}</Typography>
                  {row?.action === 'updated' ? (
                    <>
                      <Typography variant="body2" component="div">
                        <strong>Changes:</strong>
                      </Typography>
                      {renderMetadataChanges(row?.metadata)}
                    </>
                  ) : (
                    <></>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </>
      )}
    </section>
  );
};

export default ItemTimelinePreview;
